import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Globální druhová rozmanitost" />
    <h1>Globální druhová rozmanitost</h1>

    <p>Glob&aacute;ln&iacute; druhov&eacute; bohatstv&iacute; je jeden z typů <Link to="/biogeografie/biodiverzita/">biodiverzity</Link> vyjadřuj&iacute;c&iacute; počet unik&aacute;tn&iacute;ch druhů na planetě Zemi. Celkem je zn&aacute;mo asi 2 miliony druhů, přičemž valn&aacute; vět&scaron;ina existuj&iacute;c&iacute;ch druhů vy&scaron;&scaron;&iacute;ch rostlin a obratlovců je pops&aacute;no.</p>
    <p>Naopak nezn&aacute;m&yacute;ch druhů hub je asi 1,5&nbsp;mil. a nezn&aacute;m&yacute;ch druhů hmyzu až několik mili&oacute;nů.</p>
<hr />
    <h2>Latitudin&aacute;ln&iacute; gradient diverzity (LGD)</h2>
    <p>Latitudin&aacute;ln&iacute; gradient diverzity (zkratka LGD) je nejn&aacute;padněj&scaron;&iacute;m glob&aacute;ln&iacute;m jevem biodiverzity, kdy druhov&aacute; bohatost kles&aacute; se stoupaj&iacute;c&iacute; zeměpisnou &scaron;&iacute;řkou (latitude). Tj. biodiverzita je nejvy&scaron;&scaron;&iacute; na rovn&iacute;ku a nejniž&scaron;&iacute; na p&oacute;lech.</p>
    <p>Až na v&yacute;jimky (např. mořsk&yacute; plankton, m&scaron;ice, travinn&aacute; vegetace aj.) plat&iacute; pro vět&scaron;inu taxonů.</p>
    <h3>Hypot&eacute;zy vysvětluj&iacute;c&iacute; LGD</h3>
    <p>Dodnes nen&iacute; latitudin&aacute;ln&iacute; gradient diverzity uspokojivě vysvětlen, př&iacute;činami gradientu se v&scaron;ak zab&yacute;val už Darwin či von Humboldt. Hypot&eacute;z pod&aacute;vaj&iacute;c&iacute; vysvětlen&iacute; existuje cel&aacute; řada, n&iacute;že jsou uvedeny alespoň ty nejv&yacute;znamněj&scaron;&iacute;:</p>
    <ul>
    <li><strong>vět&scaron;&iacute; plocha tropů</strong></li>
    <li><strong>efekt středu dom&eacute;ny</strong></li>
    <li><strong>skupina hypot&eacute;z založen&yacute;ch na vět&scaron;&iacute;m př&iacute;sunu energie v&nbsp;tropech</strong></li>
    <li><strong>hypot&eacute;zy založen&eacute; na historick&yacute;ch př&iacute;čin&aacute;ch</strong></li>
    </ul>
    <h3>H1: Tropy zauj&iacute;maj&iacute; vět&scaron;&iacute; plochu</h3>
    <p>M. Rosenzweig v 90. letech vyslovil hypot&eacute;zu, kter&aacute; vysvětluje LGD t&iacute;m, že tropick&yacute; p&aacute;s zauj&iacute;m&aacute; největ&scaron;&iacute; plochu. Nav&iacute;c je zde stabiln&iacute; teplota, což umožňuje existenci velk&yacute;ch are&aacute;lů. D&iacute;ky tomu je zde niž&scaron;&iacute; pravděpodobnost z&aacute;niku druhů a vy&scaron;&scaron;&iacute; pravděpodobnost alopatrick&eacute; či parapatrick&eacute; <Link to="/biogeografie/evolucni/speciace/">speciace</Link>.</p>
    <p>Hypot&eacute;za m&aacute; v&scaron;ak siln&eacute; trhliny. Tropick&eacute; druhy totiž maj&iacute; sp&iacute;&scaron;e mal&eacute; are&aacute;ly. Nav&iacute;c velikost are&aacute;lu nen&iacute; podm&iacute;něna plochou klimatick&eacute;ho p&aacute;su, ale sp&iacute;&scaron; plochou jednotliv&yacute;ch biomů. A tropick&eacute; biomy nejsou vět&scaron;&iacute; než netropick&eacute;.</p>
    <h3>H2: Efekt středu dom&eacute;ny</h3>
    <p>Colwell a Hurt při&scaron;li v roce 1994 s hypot&eacute;zou efektu středu dom&eacute;ny. Ta vych&aacute;z&iacute; z premisy, že při n&aacute;hodn&eacute;m rozm&iacute;stěn&iacute; are&aacute;lů druhů na Zemi pod&eacute;l severo-jižn&iacute; osy se jich nejv&iacute;ce překr&yacute;v&aacute; uprostřed, tedy v tropech. Jde o geometrick&yacute; důsledek toho, že velk&eacute; are&aacute;ly budou vždy zasahovat do středu dom&eacute;ny, zat&iacute;mco nemus&iacute; b&yacute;t př&iacute;tomny na okraj&iacute;ch.</p>
    <p>Probl&eacute;mem je, že dom&eacute;nami jsou sp&iacute;&scaron;e kontinenty, ne cel&aacute; Země. Z testu efektu středu dom&eacute;ny na are&aacute;lech pt&aacute;ků (Storch et al., 2006) vy&scaron;la diverzita nejvy&scaron;&scaron;&iacute; uprostřed kontinentů. Pokud se v&scaron;ak tento efekt moduloval produktivitou prostřed&iacute; (předpoklad, že se druhy &scaron;&iacute;ř&iacute; v&iacute;ce do m&iacute;st s vy&scaron;&scaron;&iacute; produktivitou), v&yacute;sledek se bl&iacute;ž&iacute; skutečnosti.</p>
    <h3>H3: V&iacute;ce energie v tropech (SER)</h3>
    <p>LGD koreluje velmi dobře s průměrnou ročn&iacute; teplotou vzduchu, &uacute;hrnem atmosferick&yacute;ch sr&aacute;žek a dostupnost&iacute; energie. Rozd&iacute;ly v biodiverzitě jsou tak dobře vysvětliteln&eacute; prim&aacute;rn&iacute; produktivitou prostřed&iacute; (NPP), tedy produkc&iacute; rostlinn&eacute; biomasy za jednotku času na jednotce plochy.</p>
    <p>Hypot&eacute;za vztahu diverzity druhů a množstv&iacute; energie (zkratka SER z anglick&eacute;ho Species-energy relationship) vysvětluje vy&scaron;&scaron;&iacute; počet druhů v těchto oblastech prostřednictv&iacute;m hypot&eacute;zy MIH (zkratka z anglick&eacute;ho More Individuals Hypothesis). MIH tvrd&iacute;, že v&iacute;ce energie podmiňuje vět&scaron;&iacute; množstv&iacute; jedinců ve společenstvu. SER pak předpokl&aacute;d&aacute;, že vy&scaron;&scaron;&iacute; počet jedinců pak umožňuje m&iacute;t vět&scaron;&iacute;mu počtu druhů životaschopn&eacute; populace.</p>
    <p>Probl&eacute;mem tohoto vysvětlen&iacute; latitud&aacute;ln&iacute;ho gradientu je fakt, že vztah mezi energi&iacute; a počtem jedinců je slab&scaron;&iacute; než mezi energi&iacute; a počtem druhů. Nav&iacute;c, z&aacute;vislost počtu druhů na počtu jedinců nen&iacute; nikterak siln&aacute;.</p>
    <h3>H4: Historick&eacute; př&iacute;činy</h3>
    <p>Hypot&eacute;zy založen&eacute; na historick&yacute;ch př&iacute;čin&aacute;ch se v z&aacute;sadě děl&iacute; do dvou odli&scaron;n&yacute;ch, protichůdn&yacute;ch skupin:</p>
    <ul>
    <li><strong>tropy jako muzeum</strong> (v&iacute;ce stabiln&iacute;, m&eacute;ně vym&iacute;r&aacute;n&iacute;)</li>
    <li><strong>tropy jako kol&eacute;bka</strong> (rychlej&scaron;&iacute; vznik&aacute;n&iacute; druhů, dobře adaptovan&eacute; druhy se &scaron;&iacute;ř&iacute; do vy&scaron;&scaron;&iacute;ch z.&scaron;.)</li>
    </ul>
    <h4>H4a: Tropy jako muzeum</h4>
    <p>Hypot&eacute;za, kter&aacute; bere tropy jako obrazn&eacute; muzeum, předpokl&aacute;d&aacute; ochuzen&iacute; vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řek vlivem dob ledov&yacute;ch. Řada druhů vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řek vymřela a ty, co nevymřely, se nemusely vr&aacute;tit zpět.</p>
    <p>Vy&scaron;&scaron;&iacute; zeměpisn&eacute; &scaron;&iacute;řky jsou dlouhodobě klimaticky nestabiln&iacute;, s vy&scaron;&scaron;&iacute; sezonalitou. To zabraňuje &uacute;zkou specializaci druhů. D&iacute;ky mal&eacute; specializaci nemůže pak na jednom stanovi&scaron;ti koexistovat vět&scaron;&iacute; množstv&iacute; druhů.</p>
    <p>Probl&eacute;mů m&aacute; teorie hned několik:</p>
    <ul>
    <li>men&scaron;&iacute; vliv glaci&aacute;lů na jižn&iacute; polokouli</li>
    <li>tropy byly v glaci&aacute;lu tak&eacute; postiženy, a to suchem</li>
    <li>absence dostatečn&yacute;ch důkazů o vy&scaron;&scaron;&iacute; specializaci v tropech</li>
    <li>LGD je star&scaron;&iacute; než kvart&eacute;r, ve kter&eacute;m se glaci&aacute;ly objevovaly</li>
    </ul>
    <h5>Moderněj&scaron;&iacute; verze pracuj&iacute;c&iacute; se vzd&aacute;leněj&scaron;&iacute; minulost&iacute;</h5>
    <p>Moderněj&scaron;&iacute; verze pracuje s minulost&iacute; 55 až 34 mil. let, kdy tropick&yacute; p&aacute;s sahal až k p&oacute;lům. Je založena na <strong>konzervatismu nik</strong>, tedy na hypot&eacute;ze, že vět&scaron;ina evolučn&iacute;ch lini&iacute; vznikla na historicky tropick&eacute; planetě s adaptac&iacute; na vlhk&eacute; a tepl&eacute; klima. Po chlazen&iacute; se tyto linie vět&scaron;inou neadaptovaly na chladněj&scaron;&iacute; podm&iacute;nky vy&scaron;&scaron;&iacute;ch &scaron;&iacute;řek.</p>
    <p>V tropech se podle t&eacute;to verze nahromadila řada star&yacute;ch evolučn&iacute;ch lini&iacute;, zat&iacute;mco oblasti vzd&aacute;leněj&scaron;&iacute; od rovn&iacute;ku obsadili potomci mal&eacute;ho počtu odvozen&yacute;ch lini&iacute;.</p>
    <h4>H4b: Tropy jako kol&eacute;bka</h4>
    <p>Hypot&eacute;za, kter&aacute; považuje tropy za kol&eacute;bku, m&aacute; velkou oporu v metabolick&eacute; teorii ekologie. Teplota totiž ovlivňuje rychlost v&scaron;ech biologick&yacute;ch procesů prostřednictv&iacute;m vlivu na rychlost metabolismu. V teplej&scaron;&iacute;m prostřed&iacute; ektotermn&iacute; organismy mutuj&iacute; rychleji, č&iacute;mž by měla speciace prob&iacute;hat rychleji v tropech.</p>
    <p>Historick&eacute; př&iacute;činy jsou v&scaron;ak zřejmě region&aacute;lně specifick&eacute; a zat&iacute;mco tropy star&eacute;ho svět funguj&iacute; sp&iacute;&scaron;e jako muzeum, tropy nov&eacute;ho světa jako kol&eacute;bka.</p>
    <h3>Rovnov&aacute;žn&aacute; dynamika diverzity</h3>
    <p>Rovnov&aacute;žn&aacute; dynamika diverzity je jak&yacute;msi pokusem o synt&eacute;zu v&yacute;&scaron;e uveden&yacute;ch hypot&eacute;z př&iacute;čin LGD. Tato synt&eacute;za operuje s existuj&iacute;c&iacute;mi region&aacute;lně specifick&yacute;mi limity pro maxim&aacute;ln&iacute; počty druhů (tzv. rovnov&aacute;žn&eacute; stavy biodiverzity), kter&eacute; z&aacute;vis&iacute; na:</p>
    <ul>
    <li>rychlosti speciace a př&iacute;padně imigrace</li>
    <li>množstv&iacute; energie a zdrojů</li>
    <li>stabilitě prostřed&iacute;</li>
    </ul>
<hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Globální druhová rozmanitost</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/"><button className="inv">&larr; SAR: Vztah biodiverzity a plochy</button></Link>
    <Link to="/biogeografie/biodiverzita/lokalni-druhove-bohatstvi/"><button className="inv">Lokální druhová bohatost &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
